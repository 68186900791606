<template>
  <div>
    <v-container>
      <v-card-title>
        <v-breadcrumbs class="text-uppercase font-weight-bolder" :items="items" />
        <v-spacer />
      </v-card-title>

      <v-card-text>
        <br />
        <v-spacer />
        Esta Política de Privacidade descreve como www.loja.mutue.net coleta,
        usa e protege as informações que você fornece ao utilizar nosso site
        www.loja.mutue.net.
        <br />
        <br />
        <b>1. Informações Coletadas</b>
        <br />
        Ao utilizar nosso site, podemos coletar as seguintes informações
        pessoais: Nome Endereço de e-mail Endereço residencial Informações de
        pagamento Também podemos coletar informações não pessoais, como dados
        demográficos, preferências e interesses, para melhorar nossos produtos
        e serviços.

        <br />
        <br />

        <b> 2. Informações Coletadas </b>
        <br />
        Ao utilizar nosso site, podemos coletar as seguintes informações
        pessoais: Nome Endereço de e-mail Endereço residencial Informações de
        pagamento Também podemos coletar informações não pessoais, como dados
        demográficos, preferências e interesses, para melhorar nossos produtos
        e serviços.
        <br /><br />
        <b>3. Uso das Informações </b>
        <br />
        As informações coletadas são usadas para:
        <br />
        Processar pedidos e pagamentos Entregar produtos e serviços
        Personalizar sua experiência de compra Enviar e-mails promocionais
        sobre novos produtos, ofertas especiais ou outras informações que
        possam interessar a você (você pode optar por não receber esses
        e-mails a qualquer momento) Proteção das Informações Nós nos
        comprometemos a garantir a segurança e privacidade das suas
        informações. Implementamos medidas de segurança adequadas para
        proteger contra acesso não autorizado, alteração, divulgação ou
        destruição dessas informações.
        <br />
        <br />
        <b>4. Cookies </b>
        <br />
        Nosso site pode usar cookies para melhorar sua experiência de usuário.
        Você pode optar por aceitar ou recusar esses cookies modificando as
        configurações do seu navegador.

        <br />
        <br />
        <b> 5. Divulgação a Terceiros </b>
        <br />
        Não vendemos, comercializamos ou transferimos suas informações
        pessoais para terceiros sem seu consentimento, exceto quando
        necessário para fornecer um serviço solicitado por você (por exemplo,
        entrega de produtos).
        <br />
        <br />
        <b> 6. Links para Sites Externos </b>
        <br />
        Nosso site pode conter links para sites externos. Não somos
        responsáveis pelas práticas de privacidade desses sites. Recomendamos
        que você leia as políticas de privacidade deles.
        <br />
        <br />

        <b> 7. Seus Direitos </b>
        <br />
        Você tem o direito de acessar, corrigir ou apagar suas informações
        pessoais a qualquer momento. Se desejar exercer esses direitos ou
        tiver dúvidas sobre nossa política de privacidade, entre em contato
        conosco através das informações fornecidas abaixo.
        <br />
        <br />
        <b> 8. Alterações nesta Política de Privacidade </b>

        <br />
        Reservamos o direito de atualizar esta política a qualquer momento.
        Recomendamos que você revise periodicamente para estar ciente de
        quaisquer alterações. Ao utilizar nosso site, você concorda com esta
        política de privacidade. Contato Se você tiver alguma dúvida sobre
        esta Política de Privacidade, entre em contato conosco: <br />
        <b> Mutue Loja Online </b> <br />
        <b> +244 934 66 00 03 </b> <br />
        <b> loja@mutue.net </b> <br />
      </v-card-text>
    </v-container>
  </div>
</template>

<script>
  import CardCart from '@/components/cartCard/CardCart'
  export default {
    components: {
      CardCart,
    },
     metaInfo() {
    return {
      title: 'Quem Somos - Mutue Loja Online',
      meta: [
        {
          name: 'Quem Somos',
          content: 'A Mutue Loja Online está à sua disposição, pronta para transformar a maneira como você faz compras online. Imagine um mundo onde a busca pelo produto perfeito, a facilidade de compra e a satisfação do cliente se unem em um só lugar. Bem-vindo a esse mundo - bem-vindo à Mutue!',
        },
    ],
    };
  },
    data () {
      return {
        isWeb:false,
        items: [
          {
            text: 'Início',
            disabled: false,
            href: '/',
          },
          {
            text: 'Políticas de Privacidade',
            disabled: true,
            href: '/',
          },
        ],

        tab: null,
        page: 1,
        itemsPerPage: 8,
      }
    },
    computed: {
      numberOfPages () {
        return Math.ceil(this.items.length / this.itemsPerPage)
      },
    },
    created () {
      window.addEventListener('scroll', this.handleScroll)
    this.isWeb = this.$vuetify.breakpoint.name === 'xl' || this.$vuetify.breakpoint.name==='lg' || this.$vuetify.breakpoint.name==='md' ;
 
    },
    destroyed () {
      window.removeEventListener('scroll', this.handleScroll)
    },
    methods: {
      nextPage () {
        if (this.page + 1 <= this.numberOfPages) this.page += 1
      },
      formerPage () {
        if (this.page - 1 >= 1) this.page -= 1
      },
    },
  }
</script>

<style lang="scss" scoped>
.vendor-card {
  .vendor-avatar {
    position: absolute;
    top: -28px;
    left: 20px;

    img {
      border: 4px solid #fff;
    }
  }

  .vendor-btn {
    position: absolute;
    bottom: 6px;
  }
}
</style>
